.not-found-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 4rem;
}

.not-found-img {
    width: 150px;
}

.not-text-container {
    max-width: 50vw;
    text-align: justify;
    font-family: var(--main-font);
    font-size: 18px;
}

.not-found-root {
    padding: 0rem 0rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* height: 60vh; */
    gap: 2rem;
    /* width: calc(100% - 8rem); */
    max-width: 50vw;
}

.not-found-signature {
    font-weight: 500;
    font-family: var(--main-font);
}

.signature-container {
    width: 100%;
    display: flex;
}

.not-found-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
}

.not-found-h1 {
    font-family: var(--main-font);
    font-weight: 500;
    font-size: 40px;
    /* width: 50vw; */;
}


@media (max-width: 1048px) {

    .not-found-h1 {
        font-size: 35px;
    }
}

@media (max-width: 935px) {
    .not-found-root {
        padding: 2rem 2rem;
        width: calc(100% - 4rem);
        max-width: 100%;
    }

    .not-found-h1 {
        font-size: 35px;
        width: 100%;
        text-align: center;
    }
    
    .not-text-container {
        max-width: 100%;
        margin-top: -25px;
    }
}

@media (max-width: 512px) {

    .not-found-h1 {
        font-size: 25px;
        width: 100%;
        text-align: center;
    }

} 